import React, { Component } from 'react';
import './App.scss';
import GoogleTagManager from './GoogleTagManager';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
  Switch,
  BrowserRouter,
  // withRouter,
  Redirect,
  Route
} from 'react-router-dom';
// import { Redirect } from 'react-router';

//Apollo for GraphQL
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

// import BottomNavigation, { BottomNavigationAction } from 'material-ui/BottomNavigation';
import Typography from '@material-ui/core/Typography';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';
// import Popper from '@material-ui/core/Popper';
// import MenuItem from '@material-ui/core/MenuItem';
// import MenuList from '@material-ui/core/MenuList';
// import Button from 'material-ui/Button';
// import Button from '@material-ui/core/Button';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
// import { createMuiTheme } from 'material-ui/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
// import NavBar from './pages-other/NavBar';
// import Login from './pages-other/Login';
// import Logout from './pages-other/Logout';
// import Signup from './pages-other/Signup';
// import HomeTwo from './pages-home/HomeTwo.js';
// import HomeThree from './pages-home/NewHome/HomeThree';
// import ResearchAll from './pages-research/ResearchAll.js';
// import ResearchAll2 from './pages-research/NewResearch/ResearchAll.js';
// import ResearchLandingPage from './pages-research/NewResearchLandingPage/ResearchLandingPage';
// NOTE: Don't comment ResearchLandingPage out, it will cause a CSS bug on Craft archive page dropdown menu margins.
import ResearchLandingPage from './pages-research/CustomPages/Redesign2022/researchRedesign';
// import CareerResearch from './pages-research/NewConstructs/CareerResearch';
// import CognitiveResearch from './pages-research/NewConstructs/CognitiveResearch';
// import CreativityResearch from './pages-research/NewConstructs/CreativityResearch';
// import EquityResearch from './pages-research/NewConstructs/EquityResearch';
// import GroupResearch from './pages-research/NewConstructs/GroupResearch';
// import MotivationResearch from './pages-research/NewConstructs/MotivationResearch';
// import MethodResearch from './pages-research/NewConstructs/MethodResearch';
// import SocialResearch from './pages-research/NewConstructs/SocialResearch';
// import TechnologyResearch from './pages-research/NewConstructs/TechnologyResearch';
import PhilanthropyLandingPage from './pages-philanthropy/PhilanthropyLandingPage';
// import RuralSchools from './pages-philanthropy/CustomPages/RuralSchools/ruralSchools';
// import PTSAStory from './pages-philanthropy/Stories/PTSA-Story';
// import CommunityResponsivePhil from './pages-philanthropy/Stories/CommunityResponsivePhil';
// import TakeHomeBookProgram from './pages-philanthropy/Stories/TakeHomeBookProgram';
// import MultiFacetedOrg from './pages-philanthropy/Stories/MultiFacetedOrg';
// import OrgFlexCrisisResponse from './pages-philanthropy/Stories/OrgFlexCrisisResponse';
// import ArtEducation from './pages-philanthropy/Stories/artEducation/artEducation';
// import ComicsToClassroom from './pages-philanthropy/Stories/comicsToClassrooms/comicsToClassrooms';
// import HilltopSchool from './pages-philanthropy/Stories/hilltopSchool/hilltopSchool';
// import NewsPage from './pages-news/NewsPage';
// import EquityPage from './pages-equity/EquityPage';
// import Research from './pages-individ/Research';
import Research2 from './pages-individ/NewResearch/Research';
// import Programs from './pages-programs/Programs.js';
// import Programs2 from './pages-programs/Programs2.js';
// import Programs3 from './pages-programs/NewPrograms/Programs.js';
// import AboutUs from './pages-about/AboutUs';
import Careers from './pages-about/NewAbout/Careers/Careers.js';
// import Jobs from './pages-about/NewAbout/Jobs/Jobs.js';
// import WhatWeDo from './pages-about/NewAbout/WhatWeDo/WhatWeDo.js';
// import OurStory from './pages-about/NewAbout/OurStory/OurStory.js';
// import Team from './pages-about/NewAbout/Team/Team.js';
// import BoaAndIrb from './pages-about/BoaAndIrb';
// import Contact from './pages-contact/Contact.js';
import Contact2 from './pages-contact/NewContact/Contact';
// import SubjectArea from './pages-individ/SubjectArea';
import SubjectArea2 from './pages-individ/NewInterestArea/SubjectArea2';
// import Program from './pages-individ/Program';
// NOTE: Do not comment out Program2, some components still use the styling from it
import Program2 from './pages-individ/NewProgram/Program';
// import Program2 from './pages-individ/Program2';
// import People from './pages-individ/People';
// import ArtsReviews from './pages-vrblog/artsReviews';
// import EmpathyReviews from './pages-vrblog/empathyReviews';
// import FunReviews from './pages-vrblog/funReviews';
// import HistoryReviews from './pages-vrblog/historyReviews';
// import MathReviews from './pages-vrblog/mathReviews';
// import ScienceReviews from './pages-vrblog/scienceReviews';
// import VisualizationReviews from './pages-vrblog/visualizationReviews';
// import PeopleContent from './pages-individ/NewPeople/People-Content';
// import People2 from './pages-individ/NewPeople/People2';
// import IndividCareer from './pages-individ/IndividCareer';
// import LearningConcept from './pages-individ/LearningConcept';
// import Dashboard from './pages-dashboard/Dashboard';
// import DashboardPrograms from './pages-dashboard/DashboardPrograms';
// import DashboardCareers from './pages-dashboard/DashboardCareers';
// import DashboardResearch from './pages-dashboard/DashboardResearch';
// import Newsletter from './pages-other/Newsletter';
// import BluePrint from './pages-other/BluePrint';
// import StudentWork from './pages-student-work/StudentWork';
// import StudentWork2 from './pages-student-work2/StudentWork2';
// import DashboardStudentWork from './pages-dashboard/DashboardStudentWork';
// import IndividWork from './pages-individ/IndividWork';
// import IndividWork2 from './pages-individ/IndividWork2';
// import Events from './pages-events/Events';
import EverydayUnseen from './pages-student-highlights/everyday-unseen/Everyday-Unseen';
import ShortRun from './pages-student-highlights/short-run/shortRun';
// import TeachWithPortals from './pages-other/TeachWithPortals';
// import CompGaming from './pages-test/competitiveGaming';
import ResourcesLandingPage from './pages-highlights/trades/marine-science-resources/resourcesLandingPage';
import MarineScienceCollaboration from './pages-highlights/trades/marine-science-collaboration/marineScienceCollaboration';
import MarineEndlessBlue from './pages-highlights/trades/marine-endless-blue/marineEndlessBlue';
// import MarinePartnerships from './pages-highlights/trades/marine-science-partnerships/marinePartnerships';
import MarineInternships from './pages-highlights/trades/marine-science-internships/marineInternships';
import MarineBycatch22 from './pages-highlights/trades/marine-science-bycatch22/marineBycatch22';
// import Scroll from './pages-test/scroll';
// import Scroll from './pages-test/Scroll/Scroll';
import MarineScienceCareers from './pages-highlights/trades/marine-science-careers/MarineScienceCareers';
import GaryGreene from './pages-highlights/trades/marine-science-careers/People/Gary-Greene';
import BethelleeHerrmann from './pages-highlights/trades/marine-science-careers/People/Bethellee-Herrmann';
import MelissaMarquez from './pages-highlights/trades/marine-science-careers/People/Melissa-Marquez';
import CarleeJackson from './pages-highlights/trades/marine-science-careers/People/Carlee-Jackson';
import BrennaMaloney from './pages-highlights/trades/marine-science-careers/People/Brenna-Maloney';
import SaraJax from './pages-highlights/trades/marine-science-careers/People/Sara-Jax';
import AbrianJaclynYasmin from './pages-highlights/trades/marine-science-careers/People/Abrian-Jaclyn-Yasmin';
import HeidiJessie from './pages-highlights/trades/marine-science-careers/People/Heidi-Jessie';
import MikaylaMarc from './pages-highlights/trades/marine-science-careers/People/Mikayla-Marc';
import JoseGuzman from './pages-highlights/trades/marine-science-careers/People/Jose-Guzman';
import JoeCaitlin from './pages-highlights/trades/marine-science-careers/People/Joe-Caitlin';
import JoannePark from './pages-highlights/trades/marine-science-careers/People/Joanne-Park';
import BrandiDomJake from './pages-highlights/trades/marine-science-careers/People/Brandi-Dom-Jake';
import TanyaRyan from './pages-highlights/trades/marine-science-careers/People/Tanya-Ryan';

// Job Posts
// import CommunicationsDirector from './pages-about/NewAbout/Jobs/Communications-Director/Communications-Director';
// import DigitalAudioProgramDeveloper from './pages-about/NewAbout/Jobs/Digital-Audio-Program-Developer/DigitalAudio';
// import DigitalAudioTeachingArtist from './pages-about/NewAbout/Jobs/Digital-Audio-Teaching-Artist/DigitalAudioTA';
// import DigitalContentWriter from './pages-about/NewAbout/Jobs/Digital-Content-Writer/Digital-Content-Writer';
// import GapIntern from './pages-about/NewAbout/Jobs/Gap-Intern/GapIntern';
// import GraduateResearchIntern from './pages-about/NewAbout/Jobs/Graduate-Research-Intern/Graduate-Research-Intern';
// import HRAssistant from './pages-about/NewAbout/Jobs/HR-Assistant/HR-Assistant';
// import MarineSciencesEducator from './pages-about/NewAbout/Jobs/Marine-Sciences-Educator/Marine-Sciences-Educator';
// import MarineScienceIntern from './pages-about/NewAbout/Jobs/Marine-Science-Intern/Marine-Science-Intern';
// import OperationsManager from './pages-about/NewAbout/Jobs/Operations-Manager/Operations-Manager';
// import PeopleCultureStrategist from './pages-about/NewAbout/Jobs/People-Culture-Strategist/People-Culture-Strategist';
import PhilanthropyDirector from './pages-about/NewAbout/Jobs/Philanthropy-Director/Philanthropy-Director';
// import ProgramCoordinator from './pages-about/NewAbout/Jobs/Program-Coordinator/Program-Coordinator';
// import Receptionist from './pages-about/NewAbout/Jobs/Receptionist/Receptionist';
// import ResearchCoordinator from './pages-about/NewAbout/Jobs/Research-Coordinator/Research-Coordinator';
// import ResearchIntern from './pages-about/NewAbout/Jobs/Research-Intern/Research-Intern';
// import SeniorSocialScienceResearcher from './pages-about/NewAbout/Jobs/Social-Science-Researcher/Social-Science-Researcher';
// import SocialMediaAssociate from './pages-about/NewAbout/Jobs/Social-Media-Intern/SocialMediaIntern';
// import TempToHireWriter from './pages-about/NewAbout/Jobs/Temp-To-Hire-Writer/Temp-To-Hire-Writer';


//STEM Custom Pages
import StemAfterschool from './pages-highlights/trades/stem/stem-afterschool/afterschoolLandingPage';
import StemRobotics from './pages-highlights/trades/stem/stem-robotics/roboticsLandingPage';
import StemWorkshops from './pages-highlights/trades/stem/stem-workshops/workshopsLandingPage';
import StemGoBabyGo from './pages-highlights/trades/stem/stem-goBabyGo/stemGoBabyGo';

//Dance Custom Pages
// import BeyondBoundaries from './pages-highlights/trades/dance/beyond-boundaries/beyondBoundaries';
import DanceClubSupportForm from './pages-highlights/trades/dance/beyond-boundaries/clubSupportForm/clubSupportForm';

//Dramatic Arts Custom Pages
import Kcpt from './pages-highlights/trades/dramatic-arts/kcpt-partnership/kcpt';

//EdTech Custom Pages
import VrLearningMap from './pages-custom/vrLearningMap/vrLearningMap';
// import VrResources from './pages-highlights/trades/ed-tech/vr-resources/vrResources';
// import VrLandingPage from './pages-vrblog/vrLandingPage/vrLandingPage';
// import VRBlog from './pages-individ/NewVRBlog/VRBlog';

//Financial Education Custom Pages
// import Adolescents from './pages-highlights/trades/financial-education/adolescents/adolescentsLandingPage';
import SavvyLadies from './pages-highlights/trades/financial-education/savvy-ladies/savvyLadies';

// Health Education Custom Pages
import ReturnToLearn from './pages-highlights/trades/health-education/return-to-learn/returnToLearn';
import Insight from './pages-highlights/trades/health-education/insight/insight';
import CenterHumanServices from './pages-highlights/trades/health-education/center-human-services/centerHumanServices';
import Smart from './pages-highlights/trades/health-education/smart/smart'; 

// Continueing Education Custom Pages
// import CeScholarships from './pages-highlights/trades/ce-scholarships/ceScholarships';

// Trades Custom Pages
import AutoLandingPage from './pages-highlights/trades/auto-landing/autoLandingPage';
// import TranslationInterpretation from './pages-highlights/trades/translation-interpretation/translationInterpretation';
// import Culinary from './pages-highlights/trades/culinary/culinary';
import CteEd from './pages-highlights/trades/cte-educators/cteEd';
import TripsLandingPage from './pages-highlights/trades/auto-trips/tripsLandingPage';
import ProjectsLandingPage from './pages-highlights/trades/auto-projects/projectsLandingPage';
import ElectricDragster from './pages-highlights/trades/auto-projects/electric-dragster';
import HotRodCombustionMeadowdale from './pages-highlights/trades/auto-projects/hotRodCombustion-meadowdale';
import CircuitBoards from './pages-highlights/trades/auto-projects/circuit-boards';
import AutoResearch from './pages-highlights/trades/auto-research/autoResearch';
import Kansas from './pages-highlights/trades/auto-trips/kansas';
import Detroit from './pages-highlights/trades/auto-trips/detroit';
import LagunaSeca from './pages-highlights/trades/auto-trips/lagunaSeca';
import LongBeach from './pages-highlights/trades/auto-trips/longBeach';
// import MountVernon from './pages-highlights/trades/mount-vernon/mountVernon';
// import CarpentryLandingPage from './pages-highlights/trades/carpentry/carpentryLandingPage';

//Monthly Theme Pages
// import February21Page from './pages-monthly-theme/february21/february21';
import October21Page from './pages-monthly-theme/october21/october21';

// Research Custom Pages
import BrierTerrace from './pages-research/CustomPages/BrierTerrace/brierTerrace';
// import PublishedResearch from './pages-research/CustomPages/PublishedResearch/publishedResearch';
import ConceptualDevelopmentLab from './pages-research/CustomPages/ConceptualDevelopmentLab/conceptualDevelopmentLab';
import StemDbrLab from './pages-research/CustomPages/StemDbrLab/stemDbrLab';
// import DigitalTechnologiesLab from './pages-research/CustomPages/DigitalTechnologiesLab/digitalTechnologiesLab';
// import YouthWellnessLab from './pages-research/CustomPages/YouthWellnessLab/youthWellnessLab';

// Annual Report Pages
import AnnualReport2021 from './pages-annual-report/2021-annual-report/annualReport2021/annualReport2021';
import AnnualReport2021Programs from './pages-annual-report/2021-annual-report/annualReport2021Programs/annualReport2021Programs';
import AnnualReport2021Research from './pages-annual-report/2021-annual-report/annualReport2021Research/annualReport2021Research';
import AnnualReport2021Sharing from './pages-annual-report/2021-annual-report/annualReport2021Sharing/annualReport2021Sharing';
import AnnualReport2021Philanthropy from './pages-annual-report/2021-annual-report/annualReport2021Philanthropy/annualReport2021Philanthropy';
import AnnualReport2021Covid from './pages-annual-report/2021-annual-report/annualReport2021Covid/annualReport2021Covid';
import AnnualReport2021ArtisticDesign from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021ArtisticDesign/annualReport2021ArtisticDesign';
import AnnualReport2021Dance from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021Dance/annualReport2021Dance';
import AnnualReport2021DigitalAudio from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021DigitalAudio/annualReport2021DigitalAudio';
import AnnualReport2021DramaticArts from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021DramaticArts/annualReport2021DramaticArts';
import AnnualReport2021EdTech from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021EdTech/annualReport2021EdTech';
import AnnualReport2021FinEd from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021FinEd/annualReport2021FinEd';
import AnnualReport2021GamesLearning from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021GamesLearning/annualReport2021GamesLearning';
import AnnualReport2021Internships from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021Internships/annualReport2021Internships';
import AnnualReport2021Interventions from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021Interventions/annualReport2021Interventions';
import AnnualReport2021MarineScience from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021MarineScience/annualReport2021MarineScience';
import AnnualReport2021STEM from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021STEM/annualReport2021STEM';
import AnnualReport2021Trades from './pages-annual-report/2021-annual-report/annualReport2021_ProgramSubpages/annualReport2021Trades/annualReport2021Trades';

// IN DEVELOPEMENT - BETA

import BandLabCurriculum from './pages-highlights/trades/digital-audio/BandLab/bandLab';
// import TestHomeThree from './pages-test/NewHome/HomeThree';
import NewEquity from './pages-equity/newEquity/newEquity';
// import YouthResources from './pages-youth-resources/youthResources';
import NewNavBar from './pages-other/NavBar/NavBar';
import HealthEdSunburst from './pages-highlights/trades/health-education/sunburst-chart/sunburst';
import VrStressTest from './pages-highlights/trades/ed-tech/vr-stress-test/vrStressTest';
import AnnualReport2022 from './pages-annual-report/2022-annual-report/annualReport2022';
import AnnualReport2023 from './pages-annual-report/2023-annual-report/annualReport2023';
// import ProgramRedesign from './pages-programs/Redesign2023/programRedesign';
import EducatorResources from './pages-resources/educator-resources/educatorResources';
import ResearcherResources from './pages-resources/researcher-resources/researcherResources';
// import CreativityDesignPlay from './pages-interest-areas/CreativityDesignPlay/creativityDesignPlay';
// import InterestAreasLandingPage from './pages-interest-areas/LandingPage/interestAreasLandingPage';
// import CareerConnectedLearningLifeSkills from './pages-interest-areas/CareerConnectedLearningLifeSkills/careerConnectedLearningLifeSkills';
// import TechnologyMediaInformationLiteracy from './pages-interest-areas/TechnologyMediaInformationLiteracy/technologyMediaInformationLiteracy';
// import HealthWellBeing from './pages-interest-areas/HealthWellBeing/healthWellBeing';
import Banner from './pages-other/Banner/banner';
import HomeRedesign2023 from './pages-home/HomeRedesign2023/homeRedesign2023'
import LcpTest from './pages-test/LcpTest/lcpTest.js';

import Error404Page from './pages-other/NewSite404/newSite404';


// import axios from 'axios';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { Fragment } from 'react';

//=================================
//=================================

// New Craft CMS/GraphQL

// import "./craft-graphql/styles/main.css"
// Header is the new header/navigation
// import Header from './craft-graphql/components/base/header';
import ContentArchive from './craft-graphql/templates/content-archive';
import ResourceArchive from './craft-graphql/templates/resource-archive';
import StoryArchive from './craft-graphql/templates/story-archive';
import ProgramArchive from './craft-graphql/templates/program-archive';
import ProjectArchive from './craft-graphql/templates/project-archive';
import ResearchArchive from './craft-graphql/pages/researchArchive';
import PressArchive from './craft-graphql/templates/press-archive';
import NewsMedia from './craft-graphql/pages/newsMedia';
import PressResources from './craft-graphql/pages/pressResources';
import Entry from './craft-graphql/templates/entry';
import Project from './craft-graphql/templates/project';
import Programs from './craft-graphql/templates/programs';
import Page from './craft-graphql/templates/page';
import EquityCommitment from './craft-graphql/pages/equityCommitment';
import ResourceEntry from './craft-graphql/templates/resource-entry';
import TeamArchive from './craft-graphql/pages/team-archive';
import NewsletterArchive from './craft-graphql/pages/newsletter-archive';
import TeamEntry from './craft-graphql/pages/team-entry';
import AudienceArchive from './craft-graphql/templates/audienceArchive';
import Homepage from './craft-graphql/pages/homepage';
import WhatWeDoCraft from './craft-graphql/pages/whatWeDo.js';
import ArtistResidencyProgramCraft from './craft-graphql/pages/artistResidencyProgram.js';
import HowWeStartedCraft from './craft-graphql/pages/howWeStarted.js';
import ResourcesForYouth from './craft-graphql/pages/ResourcesForYouth/resourcesForYouth.js';
import EducationalProgramming from './craft-graphql/pages/EducationalProgramming/educationalProgramming.js';
import InterestAreas from './craft-graphql/pages/interestAreas.js';
// import YESELCraft from './craft-graphql/pages/LabPages/yeselLab.js';
import YESELCraft2 from './craft-graphql/pages/LabPages/YESEL/youthEducatorSELLab.js';
// import DTECraft from './craft-graphql/pages/LabPages/dteLab.js';
import DTECraft2 from './craft-graphql/pages/LabPages/DTE/digitalTechnologiesEducationLab.js';
// import DBRCraft from './craft-graphql/pages/LabPages/dbrLab.js';
import DBRCraft2 from './craft-graphql/pages/LabPages/DBR/stemDesignBasedResearchLab.js';
// import CDLCraft from './craft-graphql/pages/LabPages/cdLab.js';
import CDLCraft2 from './craft-graphql/pages/LabPages/CDL/conceptualDevelopmentLab.js';
import HWBCraft from './craft-graphql/pages/IATeams/HWB/healthWellBeing.js';
import CCLLSCraft from './craft-graphql/pages/IATeams/CCLLS/careerConnectedLearning.js';
import CDPCraft from './craft-graphql/pages/IATeams/CDP/creativityDesignPlay.js';
import TMILCraft from './craft-graphql/pages/IATeams/TMIL/technologyMediaLiteracy.js';
import ResearchLandingCraft from './craft-graphql/pages/ResearchLandingPage/research.js';

//Craft Legacy Subject Area Pages
import ArtisticDesignCraft from './craft-graphql/pages/LegacySubjectAreas/artisticDesign.js';
import CareerAndTechnicalEducationCraft from './craft-graphql/pages/LegacySubjectAreas/careerAndTechnicalEducation.js';
import DanceCraft from './craft-graphql/pages/LegacySubjectAreas/dance.js';
import DigitalAudioCraft from './craft-graphql/pages/LegacySubjectAreas/digitalAudio.js';
import DigitalCivicsCraft from './craft-graphql/pages/LegacySubjectAreas/digitalCivics.js';
import DramaticArtsCraft from './craft-graphql/pages/LegacySubjectAreas/dramaticArts.js';
import EdTechCraft from './craft-graphql/pages/LegacySubjectAreas/edTech.js';
import FilmCraft from './craft-graphql/pages/LegacySubjectAreas/film.js';
import FinancialEducationCraft from './craft-graphql/pages/LegacySubjectAreas/financialEducation.js';
import GamesAndLearningCraft from './craft-graphql/pages/LegacySubjectAreas/gamesAndLearning.js';
import HealthEducationCraft from './craft-graphql/pages/LegacySubjectAreas/healthEducation.js';
import InternshipsCraft from './craft-graphql/pages/LegacySubjectAreas/internships.js';
import InterventionsCraft from './craft-graphql/pages/LegacySubjectAreas/interventions.js';
import MarineScienceCraft from './craft-graphql/pages/LegacySubjectAreas/marineScience.js';
import StemCraft from './craft-graphql/pages/LegacySubjectAreas/stem.js';


// Get the preview token from the URL
let hashes = window.location.search.slice(window.location.search.indexOf('?') + 1).split('&')
let params1 = {}
hashes.map(hash => {
  let [key, val] = hash.split('=')
  params1[key] = decodeURIComponent(val)
})

// Determine if this is a Live Preview
let previewToken = params1.token;
let client = '';
if (previewToken) {
  client = new ApolloClient({
    uri: "https://found10-api.herokuapp.com/api?token="+previewToken,
    cache: new InMemoryCache()
  });
} else {
    client = new ApolloClient({
      uri: "https://found10-api.herokuapp.com/api",
      cache: new InMemoryCache()
    });
}

//=================================
//=================================

// Google Analytics
ReactGA.initialize('UA-118560897-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const muiTheme = createTheme({
    palette: {
      type: 'light'
    }
})

//Redirect function for react-router-dom to do a full page reload on redirect
const FullPageReloadRedirect = ({ to }) => {
  // Perform the redirect with a full page reload
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);

  // This component will not be rendered, as the page will reload before rendering
  return null;
};

class App extends Component {
  state = {
    open: false
  };
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      user: {},
      value: 5
    }
    // this.liftTokenToState = this.liftTokenToState.bind(this)
    // this.logout = this.logout.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    this.setState({ value });
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  // liftTokenToState(data) {
  //   this.setState({token: data.token, user: data.user})
  // }

  // logout() {
  //   localStorage.removeItem('mernToken')
  //   this.setState({token: '', user: {}})
  //   window.location = '/login';
  // }

  // componentDidMount() {
  //   AOS.init();
  //   // If there is a token in localStorage
  //   var token = localStorage.getItem('mernToken')
  //   if (token === 'undefined' || token === null || token === '' || token === undefined) {
  //     localStorage.removeItem('mernToken')
  //     this.setState({
  //       token: '',
  //       user: {}
  //     })
  //   } else {
  //     //   Validate the token against the server
  //     axios.post('/auth/me/from/token', {
  //       token: token
  //     }).then(response => {
  //       //   Store the token and user
  //       localStorage.setItem('mernToken', response.data.token)
  //       this.setState({
  //         token: response.data.token,
  //         user: response.data.user,
  //         name: response.data.name,
  //         roles: response.data.roles
  //       })
  //       //   Pass User into child components and display main app
  //     }).catch(err => {
  //       // Both the JWT and db errors will be caught here
        
  //     })
  //   }
  //   // //Add to homescreen on mobile
  //   // let deferredPrompt;
  //   // window.addEventListener('beforeinstallprompt', (e) => {
  //   //   // Prevent Chrome 67 and earlier from automatically showing the prompt
  //   //   e.preventDefault();
  //   //   // Stash the event so it can be triggered later.
  //   //   deferredPrompt = e;
  //   //   console.log('deferredPrompt: ', deferredPrompt);
  //   // });
  // }

  render() {
    return (
      <ApolloProvider client={client}>
        <GoogleTagManager gtmId='GTM-WQSKZRL' />
        <Fragment>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <div className='App'>
              {/* <NavBar id='navbar' logout={this.logout} token={this.state.token}/> */}
              {/* COMMENT BANNER BELOW IN OR OUT FOR ALERT BANNER TO SHOW  */}
              {/* <Banner /> */}
              <NewNavBar />
              <Switch>
              {/* <Route className='Application' exact path="/"
                render={(props) => <HomeTwo className='content' index={4} props={props}/>}
              /> */}

              {/* <Route className='Application' exact path="/research"
                render={(props) => <ResearchAll className='content' index={0} />}
              /> */}
              
              ==== || HOMEPAGE REDESIGN  ||==
              <Route className='Application' exact path="/"
                render={(props) => <HomeRedesign2023 className='content' index={4} props={props}/>}
              />
            
              {/* REDIRECTS, Note: Use <FullPageReloadRedirect/> to redirect to any Craft pages*/} 
              
              {/* RESEARCH REDIRECTS */}

              <Route path="/research/cognitive-development">
                <FullPageReloadRedirect to="/resources?cat=3504" />
              </Route>

              <Route path="/research/equity-and-access">
                <FullPageReloadRedirect to="/resources?cat=3069" />
              </Route>

              <Route path="/research/group-learning">
                <FullPageReloadRedirect to="/resources?cat=694" />
              </Route>

              <Route path="/research/motivation">
                <FullPageReloadRedirect to="/resources?cat=3506" />
              </Route>

              <Route path="/research/research-methods">
                <FullPageReloadRedirect to="/resources?cat=3507" />
              </Route>

              <Route path="/research/social-and-cultural-development">
                <FullPageReloadRedirect to="/resources?cat=2215" />
              </Route>

              <Route path="/research/peer-reviewed-publications">
                <FullPageReloadRedirect to="/resources?cat=1417" />
              </Route>

              <Route path="/research/50-tips-for-vr-in-the-classroom">
                <FullPageReloadRedirect to="/resources/50-tips-for-vr-in-the-classroom" />
              </Route>

              <Route path="/research/developing-identity-safety-at-young-women-empowered-create-summer-camp">
                <FullPageReloadRedirect to="/resources/developing-identity-safety-at-young-women-empowered-create-summer-camp" />
              </Route>

              <Route path="/research/elementary-lego-and-robotics">
                <FullPageReloadRedirect to="/resources/elementary-lego-and-robotics" />
              </Route>

              <Route path="/research/entrepreneurial-technology-learning">
                <FullPageReloadRedirect to="/resources/entrepreneurial-technology-learning" />
              </Route>

              <Route path="/research/filling-financial-education-gaps">
                <FullPageReloadRedirect to="/resources/filling-financial-education-gaps-what-we-can-learn-from-youth-perspectives-on-financial-education" />
              </Route>

              <Route path="/research/gap-year-2020">
                <FullPageReloadRedirect to="/resources/gap-year-2020" />
              </Route>

              <Route path="/research/here-I-can-just-be-myself-how-youth-and-adults-collaboratively-develop-an-identity-safe-community-across-difference">
                <FullPageReloadRedirect to="/resources/here-i-can-just-be-myself-how-youth-and-adults-collaboratively-develop-an-identity-safe-community-across-difference" />
              </Route>

              <Route path="/research/how-internships-can-help-high-schoolers-learn-to-build-social-capital">
                <FullPageReloadRedirect to="/resources/how-internships-can-help-high-schoolers-learn-to-build-social-capital" />
              </Route>

              <Route path="/research/how-teachers-choose-and-use-digital-audio-in-their-classroom">
                <FullPageReloadRedirect to="/resources/how-teachers-choose-and-use-digital-audio-in-their-classroom" />
              </Route>

              <Route path="/research/how-youth-can-build-social-and-emotional-skills-with-tabletop-role-playing-games">
                <FullPageReloadRedirect to="/resources/how-youth-can-build-social-and-emotional-skills-with-tabletop-role-playing-games" />
              </Route>
              
              <Route path="/research/mindful-fibers-a-program-weaving-together-fiber-arts-and-mindfulness-practices">
                <FullPageReloadRedirect to="/resources/mindful-fibers-2021-2022" />
              </Route>

              <Route path="/research/physical-computing-in-the-classroom-one-week-robotics-course">
                <FullPageReloadRedirect to="/resources/physical-computing-in-the-classroom-one-week-robotics-course" />
              </Route>

              <Route path="/research/preparing-students-for-future-work-soft-skills-perceptions-and-pedagogy-in-cte">
                <FullPageReloadRedirect to="/resources/preparing-students-for-future-work-soft-skills-perceptions-and-pedagogy-in-cte" />
              </Route>

              <Route path="/research/sensors-students-and-self">
                <FullPageReloadRedirect to="/resources/sensors-students-and-self-exploring-knowledge-self-efficacy-and-interest-impact-of-ocean-sensor-learning-on-high-school-marine-science-students" />
              </Route>

              <Route path="/research/student-led-internships-an-exploration-of-interest-motivation-and-process">
                <FullPageReloadRedirect to="/resources/student-led-internships-an-exploration-of-interest-motivation-and-process" />
              </Route>

              <Route path="/research/supporting-creative-risk-taking-and-wellness-among-diverse-young-women-and-gender-expansive-youth">
                <FullPageReloadRedirect to="/resources/isri-2023-presentation-supporting-creative-risk-taking-and-wellness-among-diverse-young-women-and-gender-expansive-youth" />
              </Route>

              <Route path="/research/teacher-attitudes-toward-technology">
                <FullPageReloadRedirect to="/resources/teacher-attitudes-toward-technology" />
              </Route>

              <Route path="/research/teaching-digital-audio-production-basics-curriculum-for-k12-educators">
                <FullPageReloadRedirect to="/resources/teaching-digital-audio-production-basics-curriculum-for-k-12-educators" />
              </Route>

              <Route path="/research/the-broken-rooms-portal-2-lesson-an-exploration-of-erroneous-examples-in-a-classroom-setting">
                <FullPageReloadRedirect to="/resources/the-broken-rooms-portal-2-lesson-an-exploration-of-erroneous-examples-in-a-classroom-setting" />
              </Route>

              <Route path="/research/understanding-social-medias-influence-on-teen-well-being">
                <FullPageReloadRedirect to="/resources/understanding-social-medias-influence-on-teen-well-being" />
              </Route>

              <Route path="/research/y-we-results-2019">
                <FullPageReloadRedirect to="/resources/supporting-young-women-and-gender-expansive-youth-in-reshaping-personal-narratives-an-intergenerational-community-mentoring-approach" />
              </Route>

              <Route path="/research/young-adults-encounters-with-financial-advice-on-tiktok">
                <FullPageReloadRedirect to="/resources/young-adults-encounters-with-financial-advice-on-tiktok-research-poster" />
              </Route>

              {/* PROGRAM REDIRECTS */}

              <Route path="/programs/artistic-design">
                <FullPageReloadRedirect to="/subject/artistic-design" />
              </Route>

              <Route path="/programs/career-and-technical-education">
                <FullPageReloadRedirect to="/subject/career-and-technical-education" />
              </Route>

              <Route path="/programs/dance">
                <FullPageReloadRedirect to="/subject/dance" />
              </Route>

              <Route path="/programs/digital-audio">
                <FullPageReloadRedirect to="/subject/digital-audio" />
              </Route>

              <Route path="/programs/digital-civics">
                <FullPageReloadRedirect to="/subject/digital-civics" />
              </Route>

              <Route path="/programs/digital-civics">
                <FullPageReloadRedirect to="/subject/digital-civics" />
              </Route>

              <Route path="/programs/dramatic-arts">
                <FullPageReloadRedirect to="/subject/dramatic-arts" />
              </Route>

              <Route path="/programs/edtech">
                <FullPageReloadRedirect to="/subject/edtech" />
              </Route>

              <Route path="/programs/film">
                <FullPageReloadRedirect to="/subject/film" />
              </Route>

              <Route path="/programs/financial-education">
                <FullPageReloadRedirect to="/subject/financial-education" />
              </Route>

              <Route path="/programs/games-and-learning">
                <FullPageReloadRedirect to="/subject/games-and-learning" />
              </Route>

              <Route path="/programs/health-education">
                <FullPageReloadRedirect to="/subject/health-education" />
              </Route>

              <Route path="/programs/internships">
                <FullPageReloadRedirect to="/subject/internships" />
              </Route>

              <Route path="/programs/interventions">
                <FullPageReloadRedirect to="/subject/interventions" />
              </Route>

              <Route path="/programs/marine-science">
                <FullPageReloadRedirect to="/subject/marine-science" />
              </Route>

              <Route path="/programs/stem">
                <FullPageReloadRedirect to="/subject/stem" />
              </Route>

              <Route path="/programs/trades">
                <Redirect to="/programs/career-and-technical-education" />
              </Route>

              {/* PHILANTHROPY REDIRECTS */}

              <Route path="/philanthropy/rural-schools">
                <FullPageReloadRedirect to="/stories/rural-schools-project-story" />
              </Route>

              <Route path="/philanthropy/ptsa-story">
                <FullPageReloadRedirect to="/stories/ptsa-story-building-connections-to-achieve-community-goals" />
              </Route>

              <Route path="/philanthropy/take-home-book-program">
                <FullPageReloadRedirect to="/stories/supporting-a-take-home-book-program" />
              </Route>

              <Route path="/philanthropy/our-work-as-a-multifaceted-organization">
                <FullPageReloadRedirect to="/stories/our-work-as-a-multifaceted-organization" />
              </Route>

              <Route path="/philanthropy/the-benefits-and-pitfalls-of-organizational-flexibility-in-crisis-response">
                <FullPageReloadRedirect to="/stories/the-benefits-and-pitfalls-of-organizational-flexibility-in-crisis-response" />
              </Route>

              <Route path="/philanthropy/the-importance-of-drivers-ed-access">
                <FullPageReloadRedirect to="/stories/youth-and-parent-voices-the-importance-of-drivers-ed-access" />
              </Route>

              <Route path="/philanthropy/art-and-education-the-value-of-high-quality-materials">
                <FullPageReloadRedirect to="/stories/art-and-education-the-value-of-high-quality-materials" />
              </Route>

              <Route path="/philanthropy/art-and-education-the-value-of-high-quality-materials">
                <FullPageReloadRedirect to="/stories/art-and-education-the-value-of-high-quality-materials" />
              </Route>

              <Route path="/philanthropy/comics-to-classrooms">
                <FullPageReloadRedirect to="/stories/comics-to-classrooms" />
              </Route>

              <Route path="/philanthropy/hilltop-school">
                <FullPageReloadRedirect to="/stories/hilltop-school-supporting-pregnant-and-parenting-students" />
              </Route>

              {/* MISCELLANEOUS REDIRECTS */}

              <Route path="/teach-with-portals">
                <FullPageReloadRedirect to="/resources/portal2" />
              </Route>

              <Route path="/programs/portal-2">
                <FullPageReloadRedirect to="/resources/portal2" />
              </Route>

              <Route path="/programs/games-and-learning/portal-2">
                <FullPageReloadRedirect to="/resources/portal2" />
              </Route>

              <Route path="/portal2">
                <FullPageReloadRedirect to="/resources/portal2" />
              </Route>

              <Route path="/portal-2">
                <FullPageReloadRedirect to="/resources/portal2" />
              </Route>

              <Route path="/rcff">
                <Redirect to="/programs/film/rough-cut-film-festival-2019" />
              </Route>

              <Route path="/team">
                <FullPageReloadRedirect to="/about/team" />
              </Route>

              <Route path="/our-story">
                <FullPageReloadRedirect to="/how-we-started" />
              </Route>

              <Route path="/whats-new-in-f10-education">
                <FullPageReloadRedirect to="/news" />
              </Route>

              <Route path="/beyond-boundaries">
                <FullPageReloadRedirect to="/programs-projects/beyond-boundaries" />
              </Route>

              <Route path="/carpentry">
                <FullPageReloadRedirect to="/programs-projects?cat=29215" />
              </Route>

              <Route path="/culinary">
                <FullPageReloadRedirect to="/programs-projects?cat=29215" />
              </Route>

            {/* Nav Bar Paths */}
            {/* <Route className='Application' exact path="/research"
              render={(props) => <ResearchLandingPage className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/programs"
              render={(props) => <Programs3 className='content' indexgit ={1} />}
              /> */}
            <Route
              className='Application' exact path="/programs"
              render={(props) => <EducationalProgramming className='content' slug="educational-programming" props={props} indexgit ={1} />}
            />
            <Route
              className='Application' exact path="/philanthropy"
              render={(props) => <PhilanthropyLandingPage className='content' index={0} />}
            />
            {/* <Route className='Application' exact path="/equity"
              render={(props) => <EquityPage className='content' index={0} />}
              /> */}
            <Route className='Application' exact path="/equity"
              render={(props) => <NewEquity className='content' index={0} />}
              />
            <Route className='Application' exact path="/resources-for-teens"
              render={(props) => <ResourcesForYouth slug="resources-for-teens-and-young-adults" props={props} />}
              />
            {/* Nested Under 'About' */}
                {/* <Route className='Application' exact path="/our-story"
                  render={(props) => <OurStory className='content' />}
                  /> */}
                <Route className='Application' exact path="/what-we-do"
                  render={(props) => <WhatWeDoCraft slug="what-we-do" props={props}/>}
                  />
                <Route className='Application' exact path="/artist-residency-program"
                  render={(props) => <ArtistResidencyProgramCraft slug="artist-residency-program" props={props}/>}
                  />
                {/* <Route className='Application' exact path="/team"
                  render={(props) => <Team className='content' />}
                  /> */}
                    {/* <Route className='Application' exact path="/team/:name"
                      render={(props) => <People2 props={props}/>}
                      /> */}
                <Route className='Application' exact path="/careers"
                  render={(props) => <Careers className='content' />}
                  />
                <Route className='Application' exact path="/contact"
                  render={(props) => <Contact2
                    className='content'
                    index={3}
                    />}
                    />
                {/* <Route className='Application' exact path="/whats-new-in-f10-education"
                  render={(props) => <October21Page props={props}/>}
                /> */}
  {/* Research Paths */}
            {/* <Route className='Application' exact path="/research/interest-areas"
              render={(props) => <ResearchAll2 className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/career-development"
              render={(props) => <CareerResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/cognitive-development"
              render={(props) => <CognitiveResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/creativity"
              render={(props) => <CreativityResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/equity-and-access"
              render={(props) => <EquityResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/group-learning"
              render={(props) => <GroupResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/motivation"
              render={(props) => <MotivationResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/research-methods"
              render={(props) => <MethodResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/social-and-cultural-development"
              render={(props) => <SocialResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/technology-and-learning"
              render={(props) => <TechnologyResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/program-interest-areas"
              render={(props) => <ResearchAll2 className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/peer-reviewed-publications"
              render={(props) => <PublishedResearch className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/research/:name"
              render={(props) => <Research2 props={props}/>}
              /> */}
      {/* Custom Research Pages */}
            <Route className='Application' exact path="/brier-terrace"
              render={(props) => <BrierTerrace props={props}/>}
              />
            {/* <Route className='Application' exact path="/conceptual-development-lab"
              render={(props) => <ConceptualDevelopmentLab props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/stem-design-based-research-lab"
              render={(props) => <StemDbrLab props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/digital-technologies-and-education-lab"
              render={(props) => <DigitalTechnologiesLab props={props}/>}
            /> */}
            {/* <Route className='Application' exact path="/youth-and-educator-sel-lab"
              render={(props) => <YouthWellnessLab props={props}/>}
            /> */}
  {/* Programs Paths */}
            {/* <Route className='Application' exact path="/programs/:name"
              render={(props) => <SubjectArea2 props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/programs/:name/:name"
              render={(props) => <Program2 props={props}/>}
              /> */}
      {/* Custom Program Pages - By Interest Area */}
            {/* Artistic Design */}
                <Route className='Application' exact path='/everyday-unseen-2018'
                  render={(props) => <EverydayUnseen/>}
                  />
                <Route className='Application' exact path='/short-run-2018'
                  render={(props) => <ShortRun/>}
                  />
            {/* Dance - No Custom Pages Yet */}
                 {/* <Route className='Application' exact path='/beyond-boundaries'
                  render={(props) => <BeyondBoundaries/>}
                  /> */}
                 <Route className='Application' exact path='/dance-team-club-support-request-form'
                  render={(props) => <DanceClubSupportForm/>}
                  />
            {/* Digital Audio - No Custom Pages Yet */}
            {/* Digital Civics - No Custom Pages Yet */}
            {/* Dramatic Arts - No Custom Pages Yet*/}
                <Route className='Application' exact path="/kcpt-partnership"
                  render={(props) => <Kcpt props={props}/>}
                  />
            {/* Film - No Custom Pages Yet*/}
            {/* Financial Education */}
                {/* <Route className='Application' exact path="/financial-ed-and-adolescents"
                  render={(props) => <Adolescents props={props}/>}
                  /> */}
                <Route className='Application' exact path="/savvy-ladies"
                  render={(props) => <SavvyLadies props={props}/>}
                  />
            {/* Games and Learning - No Custom Pages Yet*/}
            {/* Health Education */}
                <Route className='Application' exact path="/return-to-learn"
                  render={(props) => <ReturnToLearn props={props}/>}
                />
                <Route className='Application' exact path="/insight"
                  render={(props) => <Insight props={props}/>}
                />
                <Route className='Application' exact path="/center-for-human-services-partnership"
                  render={(props) => <CenterHumanServices props={props}/>}
                />
                <Route className='Application' exact path="/parent-educator-action-response"
                  render={(props) => <Smart props={props}/>}
                />
            {/* Internships - No Custom Pages Yet*/}
            {/* Interventions - No Custom Pages Yet*/}
            {/* Marine Science */}
                <Route className='Application' exact path="/marine-science-resources"
                  render={(props) => <ResourcesLandingPage props={props}/>}
                />
                <Route className='Application' exact path="/marine-science-collaboration"
                  render={(props) => <MarineScienceCollaboration props={props}/>}
                />
                <Route className='Application' exact path="/marine-science-endless-blue"
                  render={(props) => <MarineEndlessBlue props={props}/>}
                />
                {/* <Route className='Application' exact path="/marine-science-partnerships"
                  render={(props) => <MarinePartnerships props={props}/>}
                /> */}
                <Route className='Application' exact path="/marine-science-internships"
                  render={(props) => <MarineInternships props={props}/>}
                />
                <Route className='Application' exact path="/marine-science-bycatch22"
                  render={(props) => <MarineBycatch22 props={props}/>}
                />
                <Route className='Application' exact path="/marine-science-careers"
                  render={(props) => <MarineScienceCareers props={props}/>}
                  />
                {/* Careers - Nested Pages */}
                        <Route className='Application' exact path="/marine-science-careers/marine-geology"
                          render={(props) => <GaryGreene props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/oceanography"
                          render={(props) => <BethelleeHerrmann props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/marine-biology"
                          render={(props) => <MelissaMarquez props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/shark-research"
                          render={(props) => <CarleeJackson props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/science-communication"
                          render={(props) => <BrennaMaloney props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/professional-merfolk"
                          render={(props) => <SaraJax props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/art-and-design"
                          render={(props) => <AbrianJaclynYasmin props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/scuba-operations"
                          render={(props) => <HeidiJessie props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/submersible-operations"
                          render={(props) => <MikaylaMarc props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/academia"
                          render={(props) => <JoseGuzman props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/veterinary-medicine"
                          render={(props) => <JoeCaitlin props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/education-outreach"
                          render={(props) => <JoannePark props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/ocean-engineering"
                          render={(props) => <BrandiDomJake props={props}/>}
                          />
                        <Route className='Application' exact path="/marine-science-careers/ocean-policy"
                          render={(props) => <TanyaRyan props={props}/>}
                          />
            {/* STEM */}
                <Route className='Application' exact path="/stem-afterschool"
                  render={(props) => <StemAfterschool props={props}/>}
                />
                <Route className='Application' exact path="/stem-robotics"
                  render={(props) => <StemRobotics props={props}/>}
                />
                <Route className='Application' exact path="/stem-workshops"
                  render={(props) => <StemWorkshops props={props}/>}
                />
                <Route className='Application' exact path="/stem-go-baby-go"
                  render={(props) => <StemGoBabyGo props={props}/>}
                />
            {/* Trades */}
                <Route className='Application' exact path="/automotive-technology"
                  render={(props) => <AutoLandingPage props={props}/>}
                  />
                {/* Automotive Technology - Nested Pages */}
                    <Route className='Application' exact path="/automotive-research"
                     render={(props) => <AutoResearch props={props}/>}
                     />
                    <Route className='Application' exact path="/automotive-projects"
                      render={(props) => <ProjectsLandingPage props={props}/>}
                      />
                    {/* Projects - Nested Pages */}
                        <Route className='Application' exact path="/automotive-projects/electric-dragster"
                          render={(props) => <ElectricDragster props={props}/>}
                          />
                        <Route className='Application' exact path="/automotive-projects/hot-rod-combustion-and-electric-technology"
                          render={(props) => <HotRodCombustionMeadowdale props={props}/>}
                          />
                        <Route className='Application' exact path="/automotive-projects/circuit-boards"
                          render={(props) => <CircuitBoards props={props}/>}
                          />
                    <Route className='Application' exact path="/automotive-trips"
                      render={(props) => <TripsLandingPage props={props}/>}
                      />
                    {/* Trips - Nested Pages */}
                        <Route className='Application' exact path="/automotive-trips/kansas"
                          render={(props) => <Kansas props={props}/>}
                          />
                        <Route className='Application' exact path="/automotive-trips/detroit"
                          render={(props) => <Detroit props={props}/>}
                          />
                        <Route className='Application' exact path="/automotive-trips/laguna-seca"
                          render={(props) => <LagunaSeca props={props}/>}
                          />
                        <Route className='Application' exact path="/automotive-trips/long-beach"
                          render={(props) => <LongBeach props={props}/>}
                          />
                {/* Carpentry - Nested Pages */}
                    {/* <Route className='Application' exact path="/carpentry"
                      render={(props) => <CarpentryLandingPage props={props}/>}
                      /> */}
                {/* <Route className='Application' exact path="/translation-and-interpretation"
                  render={(props) => <TranslationInterpretation props={props}/>}
                  /> */}
                {/* <Route className='Application' exact path="/culinary"
                  render={(props) => <Culinary props={props}/>}
                  /> */}
                <Route className='Application' exact path="/career-technical-education-collaboration"
                  render={(props) => <CteEd props={props}/>}
                  />
                <Route className='Application' exact path="/automotive-technology"
                  render={(props) => <AutoLandingPage props={props}/>}
                  />
            {/* Virtual Reality */}
                {/* <Route className='Application' exact path="/vr-game-review"
                  render={(props) => <VrLandingPage props={props}/>}
                  />
                    <Route className='Application' exact path="/vr-game-review/:name/:name"
                      render={(props) => <VRBlog props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/arts"
                      render={(props) => <ArtsReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/empathy"
                      render={(props) => <EmpathyReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/fun"
                      render={(props) => <FunReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/history"
                      render={(props) => <HistoryReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/math"
                      render={(props) => <MathReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/science"
                      render={(props) => <ScienceReviews props={props}/>}
                      />
                    <Route className='Application' exact path="/vr-game-review/visualization"
                      render={(props) => <VisualizationReviews props={props}/>}
                      /> */}
                    <Route className='Application' exact path="/vr-learning-map"
                      render={(props) => <VrLearningMap props={props}/>}
                      />
                    {/* <Route className='Application' exact path="/vr-resources"
                      render={(props) => <VrResources props={props}/>}
                      /> */}

  {/* Philanthropy Paths */}
            {/* <Route className='Application' exact path="/philanthropy/ptsa-story"
              render={(props) => <PTSAStory className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/community-responsive-philanthropy"
              render={(props) => <CommunityResponsivePhil className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/take-home-book-program"
              render={(props) => <TakeHomeBookProgram className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/our-work-as-a-multifaceted-organization"
              render={(props) => <MultiFacetedOrg className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/rural-schools"
              render={(props) => <RuralSchools className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/the-benefits-and-pitfalls-of-organizational-flexibility-in-crisis-response"
              render={(props) => <OrgFlexCrisisResponse className='content' index={0} />}
              /> */}
            {/* <Route className='Application' exact path="/foundry10-2023-continuing-education-scholarship"
              render={(props) => <CeScholarships className='content' index={0} />}
              />  */}
            {/* <Route className='Application' exact path="/philanthropy/the-importance-of-drivers-ed-access"
              render={(props) => <MountVernon props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/art-and-education-the-value-of-high-quality-materials"
              render={(props) => <ArtEducation props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/comics-to-classrooms"
              render={(props) => <ComicsToClassroom props={props}/>}
              /> */}
            {/* <Route className='Application' exact path="/philanthropy/hilltop-school"
              render={(props) => <HilltopSchool props={props}/>}
            /> */}


    {/*Job Posts*/}
            
              {/* <Route className='Application' exact path="/careers/communications-director"
                render={(props) => <CommunicationsDirector className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/digital-audio-program-developer"
                render={(props) => <DigitalAudioProgramDeveloper className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/digital-audio-teaching-artist"
                render={(props) => <DigitalAudioTeachingArtist className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/digital-content-writer"
                render={(props) => <DigitalContentWriter className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/f10-gap-year-internship-program"
                render={(props) => <GapIntern className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/full-stack-web-developer"
                render={(props) => <Receptionist className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/graduate-research-intern"
                render={(props) => <GraduateResearchIntern className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/hr-assistant"
              render={(props) => <HRAssistant className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/marine-science-education-researcher"
                render={(props) => <MarineSciencesEducator className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/marine-science-undergraduate-intern"
                render={(props) => <MarineScienceIntern className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/operations-manager"
                render={(props) => <OperationsManager className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/people-and-culture-strategist"
                render={(props) => <PeopleCultureStrategist className='content' />}
              /> */}
              <Route className='Application' exact path="/careers/philanthropy-director"
                render={(props) => <PhilanthropyDirector className='content' />}
              />            
              {/* <Route className='Application' exact path="/careers/program-coordinator"
                render={(props) => <ProgramCoordinator className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/research-coordinator"
                render={(props) => <ResearchCoordinator className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/research-intern"
                render={(props) => <ResearchIntern className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/senior-social-science-researcher"
                render={(props) => <SeniorSocialScienceResearcher className='content' />}
                /> */}
              {/* <Route className='Application' exact path="/careers/social-media-associate"
                render={(props) => <SocialMediaAssociate className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/careers/temp-to-hire-writer"
                render={(props) => <TempToHireWriter className='content' />}
              /> */}


  {/* Annual Reports */}

            <Route className='Application' exact path="/2021-2022-annual-report"
              render={(props) => <AnnualReport2022 props={props}/>}
            />
            <Route className='Application' exact path="/2022-2023-annual-report"
              render={(props) => <AnnualReport2023 props={props}/>}
            />


            <Route className='Application' exact path="/annual-report/2021"
              render={(props) => <AnnualReport2021 className='content' index={0} />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs"
              render={(props) => <AnnualReport2021Programs className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/research"
              render={(props) => <AnnualReport2021Research className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/sharing"
              render={(props) => <AnnualReport2021Sharing className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/philanthropy"
              render={(props) => <AnnualReport2021Philanthropy className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/covid-19-response"
              render={(props) => <AnnualReport2021Covid className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/artistic-design"
              render={(props) => <AnnualReport2021ArtisticDesign className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/dance"
              render={(props) => <AnnualReport2021Dance className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/digital-audio"
              render={(props) => <AnnualReport2021DigitalAudio className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/dramatic-arts"
              render={(props) => <AnnualReport2021DramaticArts className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/ed-tech"
              render={(props) => <AnnualReport2021EdTech className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/fin-ed"
              render={(props) => <AnnualReport2021FinEd className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/games-learning"
              render={(props) => <AnnualReport2021GamesLearning className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/internships"
              render={(props) => <AnnualReport2021Internships className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/interventions"
              render={(props) => <AnnualReport2021Interventions className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/marine-science"
              render={(props) => <AnnualReport2021MarineScience className='content' />}
              />
            <Route className='Application' exact path="/annual-report/2021/programs/stem"
              render={(props) => <AnnualReport2021STEM className='content' />}
              />
              <Route className='Application' exact path="/annual-report/2021/programs/trades"
              render={(props) => <AnnualReport2021Trades className='content' />}
              />


  {/* IN DEVELOPMENT */}
            {/* <Route className='Application' exact path="/homepage-redesign"
              render={(props) => <HomeRedesign2023 props={props}/>}
            /> */}
            <Route className='Application' exact path="/bandlab-curriculum"
              render={(props) => <BandLabCurriculum props={props}/>}
            />
            {/* <Route className='Application' exact path="/test-home"
              render={(props) => <TestHomeThree props={props}/>}
            /> */}
            <Route className='Application' exact path="/2022-health-ed-highlights"
              render={(props) => <HealthEdSunburst props={props}/>}
            />
            <Route className='Application' exact path="/vr-stress-test"
              render={(props) => <VrStressTest props={props}/>}
            />
            {/* <Route className='Application' exact path="/program-redesign-2023"
              render={(props) => <ProgramRedesign props={props}/>}
            /> */}
            <Route className='Application' exact path="/resources-for-educators"
              render={(props) => <EducatorResources props={props}/>}
            />
            <Route className='Application' exact path="/resources-for-researchers"
              render={(props) => <ResearcherResources props={props}/>}
            />
            <Route className='Application' exact path="/interest-areas"
              render={(props) => <InterestAreas slug="interest-areas" props={props}/>}
            />
            {/* <Route className='Application' exact path="/interest-areas/creativity-design-and-play"
              render={(props) => <CreativityDesignPlay props={props}/>}
            /> */}
            {/* <Route className='Application' exact path="/interest-areas/career-connected-learning-and-life-skills"
              render={(props) => <CareerConnectedLearningLifeSkills props={props}/>}
            /> */}
            {/* <Route className='Application' exact path="/interest-areas/technology-media-and-information-literacy"
              render={(props) => <TechnologyMediaInformationLiteracy props={props}/>}
            /> */}
            {/* <Route className='Application' exact path="/interest-areas/health-and-well-being"
              render={(props) => <HealthWellBeing props={props}/>}
            /> */}
            {/* Press Resources - New Craft Page */}
            <Route className='Application' exact path="/press-resources"
              render={(props) => <PressResources slug="press-resources" props={props}/>}
            />
            {/* How We Started - New Craft Page */}
            <Route className='Application' exact path="/how-we-started"
              render={(props) => <HowWeStartedCraft slug="how-we-started" props={props}/>}
            />
            {/* Educational Programming - New Craft Page */}
            {/* <Route className='Application' exact path="/educational-programming"
              render={(props) => <EducationalProgramming slug="educational-programming" props={props}/>}
            /> */}
            {/* Interest Areas - New Craft Page */}
            {/* <Route className='Application' exact path="/interest-areas-new"
              render={(props) => <InterestAreas slug="interest-areas-new" props={props}/>}
            /> */}
            {/* YESEL - Craft Version of Page */}
            <Route className='Application' exact path="/youth-and-educator-sel-lab"
              render={(props) => <YESELCraft2 slug="youth-and-educator-sel-lab" props={props}/>}
            />
            {/* DTE - Craft Version of Page */}
            <Route className='Application' exact path="/digital-technologies-and-education-lab"
              render={(props) => <DTECraft2 slug="digital-technologies-and-education-lab" props={props}/>}
            />
            {/* STEM DBR - Craft Version of Page */}
            <Route className='Application' exact path="/stem-design-based-research-lab"
              render={(props) => <DBRCraft2 slug="stem-design-based-research-lab" props={props}/>}
            />
            {/* CDL - Craft Version of Page */}
            <Route className='Application' exact path="/conceptual-development-lab"
              render={(props) => <CDLCraft2 slug="conceptual-development-lab" props={props}/>}
            />

            {/* Research Landing Page - New Craft Page */}
            <Route className='Application' exact path="/research"
              render={(props) => <ResearchLandingCraft slug="research" props={props}/>}
            />

            {/* HWB - Craft Version of Page */}
            <Route className='Application' exact path="/interest-areas/health-and-well-being"
              render={(props) => <HWBCraft slug="health-and-well-being" props={props}/>}
            />

            {/* CCLLS - Craft Version of Page */}
            <Route className='Application' exact path="/interest-areas/career-connected-learning-and-life-skills"
              render={(props) => <CCLLSCraft slug="career-connected-learning-and-life-skills" props={props}/>}
            />

            {/* CDP - Craft Version of Page */}
            <Route className='Application' exact path="/interest-areas/creativity-design-and-play"
              render={(props) => <CDPCraft slug="creativity-design-and-play" props={props}/>}
            />

            {/* TMIL - Craft Version of Page */}
            <Route className='Application' exact path="/interest-areas/technology-media-and-information-literacy"
              render={(props) => <TMILCraft slug="technology-media-and-information-literacy" props={props}/>}
            />

            {/* Pagespeed Insights LCP Test Page */}
            <Route className='Application' exact path="/lcp-test"
              render={(props) => <LcpTest props={props}/>}
            />




    {/* Complete but currently unlinked on site? Do any of these belong in a different section?*/}
              {/* <Route className='Application' exact path="/news-and-announcements"
                render={(props) => <NewsPage className='content' index={0} />}
                /> */}
              {/* <Route className='Application' exact path='/student-work'
                render={(props) => <StudentWork2/>}
                /> */}


    {/* Legacy / Unfinished?  Should we comment out or remove?*/}
              {/* <Route className='Application' exact path="/about/boa-irb"
                render={(props) => <BoaAndIrb className='content'/>}
              /> */}
              {/* <Route className='Application' exact path="/login"
                render={(props) => <Login lift={this.liftTokenToState} user={this.state.user} />}
                /> */}
              {/* <Route className='Application' exact path="/signup"
                render={(props) => <Signup lift={this.liftTokenToState} />}
                /> */}
              {/* <Route className='Application' exact path="/logout"
                render={(props) => <Logout logout={this.logout} />}
                /> */}
              {/* <Route className='Application' exact path="/programs"
                render={(props) => <Programs className='content' index={1} />}
              /> */}
              {/* <Route className='Application' exact path="/about"
                render={(props) => <AboutUs className='content' index={2}/>}
              /> */}
              {/* <Route className='Application' exact path="/careers/jobs"
                render={(props) => <Jobs className='content' />}
              /> */}
              {/* <Route className='Application' exact path="/contact"
                render={(props) => <Contact
                  className='content'
                  index={3}
                  contactForm={this.contactForm}
                  />}
                /> */}
              {/* <Route className='Application' exact path='/student-work'
                render={(props) => <StudentWork/>}
              /> */}
              {/* <Route className='Application' exact path="/programs/:name"
                render={(props) => <SubjectArea props={props}/>}
              /> */}
              {/* <Route className='Application' exact path="/programs/:name/:name"
                render={(props) => <Program props={props}/>}
              /> */}
              {/* <Route className='Application' exact path="/research/:name"
                render={(props) => <Research props={props}/>}
              /> */}
              {/* <Route className='Application' exact path="/people/:name"
                render={(props) => <People props={props}/>}
                /> */}
              {/* <Route className='Application' exact path="/careers/:name"
                render={(props) => <IndividCareer props={props}/>}
                /> */}
              {/* <Route className='Application' exact path="/focusconcepts/:name"
                render={(props) => <LearningConcept props={props}/>}
                /> */}
              {/* <Route className='Application' exact path='/student-work/:name'
                render={(props) => <IndividWork props={props}/> }
              /> */}
              {/* <Route className='Application' exact path="/dashboard"
                render={(props) => <Dashboard props={props} state={this.state}/>}
                /> */}
              {/* <Route className='Application' exact path="/dashboard/careers"
                render={(props) => <DashboardCareers user={this.state.user}/>}
                /> */}
              {/* <Route className='Application' exact path="/dashboard/programs"
                render={(props) => <DashboardPrograms user={this.state.user}/>}
                /> */}
              {/* <Route className='Application' exact path="/dashboard/research"
                render={(props) => <DashboardResearch user={this.state.user}/>}
                /> */}
              {/* {console.log('this.state.user: ', this.state.user)} */}
              {/* <Route className='Application' exact path='/dashboard/student-work'
                render={(props) => <DashboardStudentWork user={this.state.user}/>}
                /> */}
              {/* <Route className='Application' exact path='/blueprint'
                render={(props) => <BluePrint/>}
                /> */}
              {/* <Route className='Application' exact path='/events'
                render={(props) => <Events/>}
                /> */}

              {/*
              ===== New Craft CMS/GraphQL Items 
              */}

               {/*
                ===== Archive Pages 
                */}
              {/* <Route className='Application' exact path="/"
                render={(props) => <Homepage props={props}/>}
              /> */}
              <Route className='Application' exact path="/news"
                render={(props) => <NewsMedia slug="news-media-archive" props={props}/>}
              />
               <Route className='Application' exact path="/stories"
                render={(props) => <StoryArchive slug="stories-archive" channel="stories" props={props}/>}
              />
              <Route className='Application' exact path="/resources"
                render={(props) => <ResourceArchive slug="resources-archive" channel="resources" props={props}/>}
              />
              {/* <Route className='Application' exact path="/programs"
                render={(props) => <ProgramArchive slug="programs-archive" channel="programs" props={props}/>}
                /> */}
              <Route className='Application' exact path="/opportunities"
                render={(props) => <ContentArchive slug="opportunities-archive" channel="opportunities" props={props}/>}
                />
              <Route className='Application' exact path="/programs-projects"
                render={(props) => <ProjectArchive slug="project-archives" channel="projects" props={props}/>}
                />
              {/* <Route className='Application' exact path="/research"
                render={(props) => <ResearchArchive slug="research-archive" props={props}/>}
                /> */}
              <Route className='Application' exact path="/news/newsletters"
                render={(props) => <NewsletterArchive slug="newsletters-archive" channel="newsletters" props={props}/>}
                />
              <Route className='Application' exact path="/news/press"
                render={(props) => <PressArchive slug="press-coverage-archive" channel="press" props={props}/>}
                />
              {/*
                ===== Entries 
                */}
              <Route className='Application' path="/stories/:slug"
                render={(props) => <Entry props={props}/>}
              />
              {/* <Route className='Application' path="/programs/:slug"
                render={(props) => <Programs props={props}/>}
                /> */}
              <Route className='Application' path="/programs-projects/:slug"
                render={(props) => <Project props={props}/>}
                />
              <Route className='Application' path="/news/press/:slug"
                render={(props) => <Entry props={props}/>}
                />
              <Route className='Application' path="/resources/:slug"
                render={(props) => <ResourceEntry props={props}/>}
              />
              <Route className='Application' path="/opportunities/:slug"
                render={(props) => <Entry props={props}/>}
              />
              <Route className='Application' exact path="/about/team"
                render={(props) => <TeamArchive props={props}/>}
                />
              <Route className='Application' path="/about/team/:slug"
                render={(props) => <TeamEntry props={props}/>}
                />
              {/*
                ===== Pages
              */}
              <Route className='Application' exact path="/equity-commitment"
                render={(props) => <EquityCommitment slug="equity-commitment" props={props}/>}
                />

              <Route className='Application' path="/audiences/:slug"
                render={(props) => <AudienceArchive slug="teens" props={props}/>}
                />

              {/*
                ===== Craft Subject Area Pages
              */}

              <Route className='Application' exact path="/subject/artistic-design"
                  render={(props) => <ArtisticDesignCraft slug="subject-artistic-design" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/career-and-technical-education"
                  render={(props) => <CareerAndTechnicalEducationCraft slug="subject-career-and-technical-education" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/dance"
                  render={(props) => <DanceCraft slug="subject-dance" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/digital-audio"
                  render={(props) => <DigitalAudioCraft slug="subject-digital-audio" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/digital-civics"
                  render={(props) => <DigitalCivicsCraft slug="subject-digital-civics" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/dramatic-arts"
                  render={(props) => <DramaticArtsCraft slug="subject-dramatic-arts" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/edtech"
                  render={(props) => <EdTechCraft slug="subject-edtech" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/film"
                  render={(props) => <FilmCraft slug="subject-film" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/financial-education"
                  render={(props) => <FinancialEducationCraft slug="subject-financial-education" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/games-and-learning"
                  render={(props) => <GamesAndLearningCraft slug="subject-games-and-learning" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/health-education"
                  render={(props) => <HealthEducationCraft slug="subject-health-education" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/internships"
                  render={(props) => <InternshipsCraft slug="subject-internships" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/interventions"
                  render={(props) => <InterventionsCraft slug="subject-interventions" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/marine-science"
                  render={(props) => <MarineScienceCraft slug="subject-marine-science" props={props}/>}
                  />
              <Route className='Application' exact path="/subject/stem"
                  render={(props) => <StemCraft slug="subject-stem" props={props}/>}
                  />
              {/*  - It would be a catch-all before redirecting to 404 */}
              {/* <Route className='Application' exact path="/matrix-modules-test"
                render={(props) => <Page props={props}/>}
                /> */}
              {/* <Route className='Application' exact path='/student-work2/:name'
                render={(props) => <IndividWork2 props={props}/> }
              /> */}
              {/* <Route className='Application' exact path='/teach-with-portals'
                render={(props) => <TeachWithPortals/>}
              /> */}
              {/* <Route className='Application' exact path="/competitive-gaming-test"
                render={(props) => <CompGaming props={props}/>}
                /> */}
              {/* <Route className='Application' exact path="/scroll"
                render={(props) => <Scroll props={props}/>}
              /> */}
              <Route path="/about">
                <Redirect to="/what-we-do" />
              </Route>

              <Route component={Error404Page}/>
            </Switch>
              {/* <Newsletter id='newsletter'/> */}
            </div>
            {/* <BottomNav /> */}
          </MuiThemeProvider>
        </BrowserRouter>
        </Fragment>
      </ApolloProvider>
    );
  }
}

export default App;